import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../likes";
import Auctions_dropdown from "../dropdown/Auctions_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { buyModalShow } from "../../redux/counterSlice";
// import LoadingCards from "../loading/LoadingCards";
// import { use } from "chai";

const CategoryItem = ({
  activeUserWallet,
  wallet, // current profile wallet address
  filter,
  balanceInfo,
  userMap,
  categoryFilter,
  tagFilter,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const { sortedtrendingCategoryItemData } = useSelector(
    (state) => state.counter
  );
  const [cards, setCards] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [tokenOwnersMap, setTokenOwnersMap] = useState({});

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // setIsLoading(true);
  //     console.log(
  //       "sortedtrendingCategoryItemData",
  //       sortedtrendingCategoryItemData
  //     );
  //     if (
  //       sortedtrendingCategoryItemData &&
  //       sortedtrendingCategoryItemData.length > 0
  //     ) {
  //       const fetchedItems = [];
  //       for (const item of sortedtrendingCategoryItemData) {
  //         try {
  //           const response = await fetch(item.uri);
  //           if (!response.ok) {
  //             throw new Error("Network response was not ok");
  //           }
  //           const data = await response.json();
  //           const newData = {
  //             ...data,
  //             token_id: item.id,
  //             creatorAddress: item.creator_address,
  //           };
  //           fetchedItems.push(newData);
  //         } catch (error) {
  //           console.error("Error fetching data:", error);
  //         }
  //       }
  //       setItems(fetchedItems);
  //     }
  //     // setIsLoading(false);
  //   };

  //   fetchData();
  // }, [sortedtrendingCategoryItemData]);
  useEffect(() => {
    if (balanceInfo.length > 0 && sortedtrendingCategoryItemData.length > 0) {
      setLoader(false);
    }
  }, [sortedtrendingCategoryItemData, balanceInfo]);

  useEffect(() => {
    const newCards = [];
    const ownersMap = {};
    // setIsLoading(true);
    balanceInfo.forEach((balance) => {
      if (!ownersMap[balance.tokenID]) {
        ownersMap[balance.tokenID] = 1;
      } else {
        ownersMap[balance.tokenID]++;
      }
    });
    for (const item of sortedtrendingCategoryItemData) {
      for (const balance of balanceInfo) {
        if (
          (filter === "owned" && balance.walletAddress === wallet) ||
          filter === "none" ||
          (filter === "created" && item.creatorAddress === wallet) ||
          (filter === "on-sale" &&
            balance.isListed &&
            balance.walletAddress === wallet) ||
          (filter === "auctions" &&
            balance.isActive &&
            balance.walletAddress === wallet)
        ) {
          if (
            item.id === balance.tokenID &&
            (item.category_type === categoryFilter ||
              categoryFilter.toLowerCase() === "all") && // for category_type filter
            (item?.category_tag?.toLowerCase() === tagFilter.toLowerCase() ||
              tagFilter === "all") // for category_tag filter
          ) {
            // split card if auction and listed both true
            const dataObj = {
              ...item, // listingType
              balance: balance.balance,
              ownerAddress: balance.walletAddress,
              isListed: balance.isListed,
              isDirect: balance.isDirect,
              isActive: balance.isActive,
              ownerName: userMap[balance.walletAddress]?.name,
              creatorName: userMap[item.creatorAddress]?.name,
              ownerProfieImage: userMap[balance.walletAddress]?.photoUrl,
              creatorUsername: userMap[item?.creatorAddress]?.username,
              ownerUsername: userMap[balance?.walletAddress]?.username,
              creatorProfileImage: userMap[item.creatorAddress]?.photoUrl,
            };
            if (filter === "owned" || filter === "created") {
              newCards.push({
                ...dataObj,
                saleAmount:
                  0 +
                  (balance.isActive && Number(balance.auctionAmount)) +
                  (balance.isListed && Number(balance.listAmount)),
                price: 0,
              });
            } else if (filter === "on-sale" && balance?.isListed) {
              newCards.push({
                ...dataObj,
                saleAmount: balance.listAmount,
                price: balance.listingPrice,
              });
            } else if (filter === "auctions" && balance?.isActive) {
              newCards.push({
                ...dataObj,
                saleAmount: balance.auctionAmount,
                price: balance.auctionPrice,
              });
            }
          }
        }
      }
    }

    setCards(newCards);
    setTokenOwnersMap(ownersMap);
    // if (balanceInfo.length > 0 && items.length > 0) setIsLoading(false);
  }, [
    sortedtrendingCategoryItemData,
    balanceInfo,
    userMap,
    categoryFilter,
    filter,
    wallet,
    tagFilter,
  ]);

  // useEffect(() => {
  //   console.log("ldfas", cards);
  // }, [cards]);

  if (loader) {
    return (
      <div className="flex gap-6 flex-wrap md:flex-nowrap justify-center items-center min-h-[40vh]">
        {/* <LoadingCards />
        <LoadingCards />
        <LoadingCards />
        <LoadingCards /> */}
        loading Nfts...
      </div>
    );
  }
  return cards.length === 0 ? (
    <div className="flex flex-col gap-6 flex-wrap md:flex-nowrap justify-center items-center min-h-[40vh]">
      {filter !== "on-sale" && filter !== "auctions" && (
        <span className="text-lg">No Nft Data Found</span>
      )}
      {filter !== "on-sale" && filter !== "auctions" && (
        <Link
          href="/CreateMain"
          className="rounded-full btn-grad px-6 py-2 font-semibold flex gap-2"
        >
          <Image
            width={6}
            height={6}
            src="/images/hero/nft.png"
            alt="nft-icon"
            className="w-6 h-auto"
          />
          <p>Create</p>
        </Link>
      )}

      {filter === "on-sale" && (
        <span className="text-lg">
          <Image
            src="/image-not-found.png"
            alt="not found"
            width={500}
            height={500}
            className="w-[111px] h-[111px] object-cover"
          />
        </span>
      )}
      {filter === "auctions" && (
        <span className="text-lg">
          <Image
            src="/image-not-found.png"
            alt="not found"
            width={500}
            height={500}
            className="w-[111px] h-[111px] object-cover"
          />
        </span>
      )}
    </div>
  ) : (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {cards.map((item, index) => {
        if (!item) {
          return null;
        }
        const itemLink =
          filter === "auctions"
            ? `/item/${item.id}?owner=${item.ownerAddress}`
            : {
                pathname: `/nftPage/${item.id}`,
                query: {
                  image: item.image || "",
                  name: item.name || "Unnamed NFT",
                  ownerAddress: item.ownerAddress || "",
                  ownerCount: tokenOwnersMap[item.id] || 0,
                  externalLink: item.external_link || "",
                  supply: item.supply || 0,
                  royalty: item.royalty || 0,
                  description: item.description || "",
                },
              };
        return (
          <article key={index}>
            <div className="border-none rounded-2.5xl block border bg-[#1e1e1e] p-[1.1875rem] transition-shadow hover:shadow-lg">
              <figure className="relative">
                <Link href={itemLink}>
                  <Image
                    width={230}
                    height={230}
                    src={item.image || "/default-image.jpg"} // Use a default image if none is provided
                    alt={item.name || "Unnamed NFT"}
                    className="w-full h-[230px] rounded-[0.625rem] object-cover"
                  />
                </Link>

                {/* <Likes like={12} /> */}

                <div className="absolute left-3 -bottom-3">
                  <div className="flex -space-x-2">
                    <Link href={`/user/${item.creatorUsername}`}>
                      <Tippy
                        className="p-2"
                        content={<span>creator: {item.creatorName}</span>}
                      >
                        <Image
                          width={21}
                          height={21}
                          src={
                            item.creatorProfileImage
                              ? item.creatorProfileImage
                              : "/images/user/user_avatar.gif"
                          }
                          alt="creator"
                          className="border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2"
                        />
                      </Tippy>
                    </Link>
                    <Link href={`/user/${item.ownerUsername}`}>
                      <Tippy
                        className="p-2"
                        content={<span>owner: {item.ownerName}</span>}
                      >
                        <Image
                          width={21}
                          height={21}
                          src={
                            item.ownerProfieImage
                              ? item.ownerProfieImage
                              : "/images/user/user_avatar.gif"
                          }
                          alt="owner"
                          className="border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2"
                        />
                      </Tippy>
                    </Link>
                  </div>
                </div>
              </figure>
              <div className="mt-7 flex items-center justify-between">
                <Link href={itemLink}>
                  <span className="font-display text-white hover:text-accent">
                    {item.name || "Unnamed NFT"}
                  </span>
                </Link>

                <Auctions_dropdown classes="dark:hover:bg-jacarta-600 dropup hover:bg-accent rounded-full " />
              </div>
              {(filter === "on-sale" || filter === "auctions") && (
                <div className="mt-2 text-sm flex gap-2">
                  <span className="text-jacarta-200  mr-1">
                    {item.price ? `${item.price} ETH` : ""}
                  </span>
                  <span className="text-jacarta-300 ">
                    {/* {item.saleAmount
                    ? `${item.saleAmount}/${
                        Number(item.saleAmount) + item.balance
                      }`
                    : ""} */}
                    {item.saleAmount ? `${item.saleAmount}/${item.supply}` : ""}
                  </span>
                </div>
              )}
              {(filter === "owned" || filter === "created") && (
                <div>
                  Balance: {item.balance + item.saleAmount}/{item.supply}
                </div>
              )}

              <div className="mt-8 flex items-center justify-between">
                <Link href={itemLink} className="group flex items-center">
                  <svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 mr-1 mb-[3px] h-4 w-4">
                    <use xlinkHref="/icons.svg#icon-history"></use>
                  </svg>
                  <span className="group-hover:text-accent font-display text-jacarta-200 text-sm font-semibold">
                    View Details
                  </span>
                </Link>
                {filter === "on-sale" &&
                  item.isListed &&
                  item.ownerAddress !== activeUserWallet &&
                  (!item.isDirect ? (
                    <Link
                      href={itemLink}
                      className="text-accent font-display text-sm font-semibold"
                    >
                      make offer
                    </Link>
                  ) : (
                    <button
                      className="text-accent font-display text-sm font-semibold"
                      onClick={() =>
                        dispatch(
                          buyModalShow({
                            tokenId: item.id,
                            image: item.image || "/default-image.jpg",
                            name: item.name || "Unnamed NFT",
                            description:
                              item.description || "No description available.",
                            externalLink: item.external_link || "",
                            supply: item.saleAmount || 0,
                            price: item.price || 0,
                            royalty: item.royalty || 0,
                            ownerWallet: item.ownerAddress || "",
                            userWallet: wallet,
                          })
                        )
                      }
                    >
                      buy now
                    </button>
                  ))}
                {filter === "auctions" &&
                  item.isActive &&
                  item.ownerAddress !== activeUserWallet && (
                    <Link
                      className="text-accent font-display text-sm font-semibold"
                      href={itemLink}
                    >
                      place bid
                    </Link>
                  )}
              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default CategoryItem;
